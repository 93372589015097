import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Api } from './api/api.service';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(private apiService: Api) { }

  public sendInfoForBayPremium(data:{email: string, promo_code?: string}) : Observable<any> {
		return this.apiService.sendPostRequest('payment', data);
  }

  public sendInConfirmForBayPremium() : Observable<any> {
		return this.apiService.sendPostRequest('payment/confirm', {});
  }

  public isDateExpired(timestamp: number): boolean {
		if (typeof timestamp !== 'number' || timestamp <= 0) {
		  return true;
		}

		const expiryDate = moment.unix(timestamp);

		return !expiryDate.isValid() || expiryDate.isBefore(moment());
	  }
}
